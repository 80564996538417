import PromoText from './PromoText';
import MealList from './MealList';
import React from 'react';

const Meals = () => {
  return (
    <>
      <PromoText />
      <MealList />
    </>
  );
};

export default Meals;
